$whatsapp: #25d366;

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Teko', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  scroll-behavior: smooth;
}

h1, h2, h3, h4{
  font-family: 'Space Grotesk', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// desktop
.canvas{
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

footer{
  background: #212124;
  padding: 60px;  
  color: white;
}
.whatsapp{
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 100%;
  max-width: 54px;
  padding: 6px 6px 0;
  background-color: $whatsapp;
  border-radius: 100%;
  z-index: 1000;
  text-align: center;
  
  a, a.visited{
    font-size: 30px;
    line-height: 30px;
    display: inline-block;
    color: white;
    vertical-align:super;
    .icon{
      font-size: 40px;
      line-height: 30px;
      border-radius: 100%
    }
  }
}

.btn-whatsapp{
  background-color: $whatsapp !important;
  color: white !important;
  display: inline-block;
  clear: both;
  font-size: 30px !important;

}


@import 'desktop.scss';

@import 'mobile.scss';

